// form validation rules
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  orderPostCode: Yup.string()
    .required('Postcode is verplicht')
    .matches(/^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/, 'Ongeldige postcode'),
  orderHouseNumber: Yup.number()
    .required('Huisnummer is verplicht')
    .typeError('Alleen cijfers zijn toegestaan'),
});
