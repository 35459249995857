import InputField from '@elements/forms/InputField';
import ListBox from '@elements/forms/ListBox';
import { handleValidation } from '@helpers/handleValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchAddress } from '@providers/profile';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { validationSchema } from './validation';

const countries = {
  label: 'Land',
  choices: [
    { value: 'Nederland' },
    { value: 'Zuid-Afrika' },
    { value: 'Frankrijk' },
    { value: 'Duitsland' },
    { value: 'Oostenrijk' },
    { value: 'België' },
  ],
};

function PostAddress({
  register,
  errors,
  handleInputChange,
  address,
  houseNumber,
  formData,
  handleListBoxChange,
  handleValidationWrapper,
  isValid,
  isShippingAddress,
  invalidAddress,
  isLoading,
}) {
  formData.toevoeging = formData.toevoeging ?? '';
  return (
    <div className="flex flex-col gap-8 pt-8">
      <div className="z-20">
        <ListBox
          choices={countries.choices}
          label={countries.label}
          register={register}
          errors={false}
          message={errors.message}
          onChange={handleListBoxChange}
        />
      </div>

      <div className="flex flex-col justify-between gap-4 md:flex-row md:gap-8">
        <div className="md:w-3/4">
          <InputField
            id="orderPostCode"
            label="Postcode"
            name="orderPostCode"
            type="text"
            register={register}
            errors={errors}
            onBlur={(e) => {
              handleValidationWrapper(e, errors?.orderPostCode);
            }}
            onKeyDown={(e) => {
              handleInputChange(e);
            }}
            onKeyUp={(e) => {
              handleInputChange(e);
            }}
            message={errors.orderPostCode?.message}
            isValid={isValid['orderPostCode']}
            defaultValue={formData.orderPostCode || ''}
          />
        </div>
        <InputField
          id="orderHouseNumber"
          label="Huisnummer"
          name="orderHouseNumber"
          type="text"
          register={register}
          errors={errors}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.orderHouseNumber);
          }}
          onKeyDown={(e) => {
            handleInputChange(e);
          }}
          onKeyUp={(e) => {
            handleInputChange(e);
          }}
          message={errors.orderHouseNumber?.message}
          isValid={isValid['orderHouseNumber']}
          defaultValue={formData.orderHouseNumber || ''}
          disabled={!isValid['orderPostCode'] || errors?.orderPostCode}
        />
        <InputField
          id="toevoeging"
          label="Toevoeging"
          name="toevoeging"
          type="text"
          register={register}
          errors={errors}
          onBlur={(e) => {
            handleValidationWrapper(e, errors?.toevoeging);
          }}
          onKeyDown={(e) => {
            handleInputChange(e);
          }}
          onKeyUp={(e) => {
            handleInputChange(e);
          }}
          message={errors.message}
          isValid={isValid['toevoeging']}
          defaultValue={formData.toevoeging || ''}
          disabled={!isValid['orderHouseNumber'] || errors?.orderHouseNumber}
        />
      </div>
      {isLoading ? (
        <Skeleton className="p-6" />
      ) : address && address.street ? (
        <div
          className={
            isShippingAddress
              ? 'bg-accent-4 p-6'
              : 'bg-accent-2 p-6 font-primary font-bold text-primary-dark'
          }
        >
          {address.street} {houseNumber + formData.toevoeging}, {address.city}
        </div>
      ) : (
        invalidAddress && (
          <div className="bg-functional-error-light p-6 font-primary font-bold text-primary-dark">
            Het adres bestaat niet
          </div>
        )
      )}
    </div>
  );
}

export default function AddressLookup({
  address,
  setAddress,
  houseNumber,
  setHouseNumber,
  selectedCountry,
  setSelectedCountry,
  setFormData,
  formData,
  isShippingAddress,
  isValid,
  setIsValid,
}) {
  const {
    register,
    formState: { errors },
    trigger,
    watch,
  } = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });

  const [invalidAddress, setInvalidAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  function handleInputChange(e) {
    setFormData((prevFomState) => ({ ...prevFomState, [e.target.name]: e.target.value }));
  }

  function handleValidationWrapper(e, error) {
    handleValidation(e, error, trigger, setIsValid);
  }

  useEffect(() => {
    let timeout;
    async function fetchAddressFromPostCode(postCode, houseNumber) {
      // Remove space so that both 1078 GA and 1078GA works
      postCode = postCode.replace(/\s/g, '');
      // Remove space so that the user can never accidentally add a space to the house number
      if (typeof houseNumber === 'number') {
        houseNumber = houseNumber.toString();
      }
      houseNumber = houseNumber.replace(/\s/g, '');

      const response = await fetchAddress(postCode, houseNumber);

      if (response && response.status === 200) {
        setAddress(response.data);
        setHouseNumber(houseNumber);
        setIsLoading(false);
      } else {
        // Reset the values if an invalid address is added
        setAddress(null);
        setHouseNumber(0);
        setInvalidAddress(true);
        setIsLoading(false);
      }
    }

    function debounceFetchAddress() {
      setIsLoading(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (
          formData.orderPostCode &&
          formData.orderHouseNumber &&
          selectedCountry.value == 'Nederland'
        ) {
          fetchAddressFromPostCode(formData.orderPostCode, formData.orderHouseNumber);
        }
      }, 500);
    }

    if (
      formData.orderPostCode &&
      formData.orderHouseNumber &&
      selectedCountry.value == 'Nederland'
    ) {
      debounceFetchAddress();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [formData]);

  function handleListBoxChange(value) {
    setSelectedCountry(value);
  }

  useEffect(() => {
    function checkValidity(fields) {
      trigger(fields).then((valid) => {
        const updatedIsValid = { ...isValid };
        for (const field of fields) {
          updatedIsValid[field] = valid;
        }
        setIsValid(updatedIsValid);
      });
    }

    const subscription = watch((value, { name }) => {
      switch (name) {
        case 'orderPostCode':
          if (value.orderPostCode) {
            checkValidity(['orderPostCode']);
          }
          return;
        case 'orderHouseNumber':
          if (value.orderHouseNumber) {
            checkValidity(['orderHouseNumber']);
          }
          return;
        case 'toevoeging':
          if (value.toevoeging) {
            checkValidity(['toevoeging']);
          }
          return;
      }
    });

    return () => subscription.unsubscribe();
  }, [isValid]);

  return (
    <div>
      <PostAddress
        formData={formData}
        register={register}
        errors={errors}
        address={address}
        houseNumber={houseNumber}
        handleInputChange={handleInputChange}
        handleListBoxChange={handleListBoxChange}
        handleValidationWrapper={handleValidationWrapper}
        isValid={isValid}
        isShippingAddress={isShippingAddress}
        invalidAddress={invalidAddress}
        isLoading={isLoading}
      />
    </div>
  );
}
